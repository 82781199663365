/* eslint-disable @next/next/no-html-link-for-pages */
// import Service from "./Services";
import React, { useEffect, useState, Component } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { PanelMenu } from "primereact/panelmenu";
import { Menubar } from "primereact/menubar";
import "primeicons/primeicons.css";
import { Dialog } from "primereact/dialog";
import { useRouter } from "next/router";
import Link from "next/link";
import { Divider } from "primereact/divider";
import "primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import Image from "next/image";
import MixpanelRecord from "../MixpanelEvent";
import LogoutConfirmation from "../Logout/index";
import UtileDulci from "../UtileDulci/index";

import getCookieValue from "../GetCookie";

export default function Sidenav() {
  // console.log(props);
  // console.log("val", props.value);
  const router = useRouter();

  const [visible, setVisible] = useState(false);
  const [profile, setProfile] = useState(false);
  const [feature, setFeature] = useState([]);
  const [policies, setPolicy] = useState([]);
  const [policydata, setPolicydata] = React.useState();
  const [showname, setShowname] = React.useState();
  const [land, setLand] = useState();

  const {
    query: { username, policy },
  } = router;
  const props = {
    username,
    policy,
  };

  const name = props.policy;
  const headers = { Authorization: "Bearer " + getCookieValue("token") };
  // const [pname, psetname] = React.useState(props.policy);

  // console.log(pname, "iuhj");

  useEffect(() => {
    // console.log(document.cookie.slice(2),"cookie")
    fetch("http://52.146.42.10:9992/api/v1/harvis/getPolicies", { headers })
      .then((response) => response.json())
      .then((data) => {
        if (data.name == "TokenExpiredError") {
          router.push(
            {
              pathname: "/Login",
            },
            "/Login"
          );
        } else {
          console.log(data, "policy data");
          setPolicy(data);
        }
      })
      .catch((err) => {
        // console.log(err.message);
      });
  }, []);

  useEffect(() => {
    let policyName = sessionStorage.getItem("policy");
    console.log(policyName, "nnn");
    policies.map((i, j) => {
      // console.log(i, "u");
      if (policyName == i.policyName) {
        setLand(i.landing);
      }
    });

    console.log(land, "hhh");
  });

  const gotoHome = () => {
    // window.location='/Home.html'
    // router.push(
    //   {
    //     pathname: "/Home",

    //     query: { username, policy },
    //   },

    //   "/Home"
    // );

    // MixpanelRecord("Revenue Logo", {
    //   message: "Navigating to Revenue Page",
    // });
    router.push({
      pathname: "/" + land,
      // query: { username, policy },
    });

    MixpanelRecord("Revenue Logo", {
      message: "Navigating to Revenue Page",
    });
  };
  const j = [];
  // let pname = props.policy

  let pname = "";

  let usernamex = "";
  useEffect(() => {
    usernamex = decodeURI(getCookieValue("name"));
    setShowname(usernamex);
  });

  useEffect(() => {
    pname = getCookieValue("t");

    console.log(pname, "pname");
    for (let f of policies) {
      console.log(f.policyName, "f");
      if (f.policyName == pname) {
        console.log(f.policyName, "policy name");
        // console.log(f.features,"iuytfg");
        for (let k of f.features) {
          // console.log(k,"itfrdesfghj");
          j.push(k);
        }
      }
    }
    setFeature(j);
  }, [pname, policies]);

  let myobj = {};

  (myobj["Revenue"] = {
    label: "Revenue",

    command: () => {
      // window.location='/Revenue.html'
      router.push(
        {
          pathname: "/Revenue",
          query: { username, policy },
        },
        "/Revenue"
      );
      setVisible(false);
      MixpanelRecord("Nav 2 Home", {
        user: getCookieValue("user"),
        message: "sideNavbar to Home",
      });
    },
  }),
    (myobj["Profile"] = {
      label: "Team",
      items: [
        {
          label: "Meet my Practice",
          icon: "pi pi-file",
          command: () => {
            // window.location='/Team.html'
            router.push(
              {
                pathname: "/Team",
                // query: { username },
                query: { username, policy },
              },
              "/Team"
            );
            setVisible(false);
            MixpanelRecord("Nav 2 Team", {
              user: getCookieValue("user"),
              message: "sideNavbar to Profiles page",
            });
          },
        },
        // {
        //   label: "Certifications",
        //   icon: "pi pi-file",
        //   command: () => {
        //     router.push(
        //       {
        //         pathname: "/Certifications",
        //         query: { username, policy },
        //       },
        //       "/Certifications"
        //     );
        //     setVisible(false);
        //     MixpanelRecord("Nav 2 Team", {
        //       message: "sideNavbar to Certifications page",
        //     });
        //   },
        // },
      ],
    }),
    (myobj["BankingPractice"] = {
      label: "Practice",
      items: [
        {
          label: "Revenue",
          icon: "pi pi-file",
          command: () => {
            // router.push("/Presale");
            // window.location='/Revenue.html'
            router.push(
              {
                pathname: "/Revenue",
                // query: { username },
                query: { username, policy },
              },
              "/Revenue"
            );
            setVisible(false);
            MixpanelRecord("Nav 2 Revenue", {
              message: "sideNavbar to Revenue page",
            });
          },
        },
        {
          label: "Dashboard",
          icon: "pi pi-file",
          command: () => {
            // window.location='/Dashboard.html'
            // router.push("/Practiceresearch");
            router.push(
              {
                pathname: "/Dashboard",
                // query: { username },
                query: { username, policy },
              },
              "/Dashboard"
            );
            setVisible(false);
            MixpanelRecord("Nav 2 Dashboard", {
              message: "sideNavbar to Dashboard page",
            });
          },
        },

        {
          label: "Presales",

          icon: "pi pi-file",

          command: () => {
            // window.location='/Presales.html'
            router.push(
              {
                pathname: "/Presales",

                query: { username, policy },
              },

              "/Presales"
            );

            setVisible(false);

            MixpanelRecord("Nav 2 Presales", {
              message: "sideNavbar to Presales page",
            });
          },
        },

        {
          label: "Research",

          icon: "pi pi-file",

          command: () => {
            // window.location='/Research.html'
            router.push(
              {
                pathname: "/Research",

                query: { username, policy },
              },

              "/Research"
            );

            setVisible(false);

            MixpanelRecord("Nav 2 Research", {
              message: "sideNavbar to Research page",
            });
          },
        },
      ],
    });

  // myobj["Hr"] = {
  //   label: "HR",
  //   command: () => {
  //     // window.location='/Hr.html'
  //     router.push(
  //       {
  //         pathname: "/Hr",
  //         query: { username, policy },
  //       },
  //       "/Hr"
  //     );
  //     setVisible(false);
  //     MixpanelRecord("Nav 2 HR", {
  //       message: "sideNavbar to HR page",
  //     });
  //   },
  // };
  myobj["Main"] = {
    label: "Home",
    command: () => {
      router.push(
        {
          pathname: "/Main",
          // query: { username, policy },
        },
        "/Main"
      );
      setVisible(false);
      MixpanelRecord("Nav 2 Home", {
        message: "sideNavbar to Home page",
      });
    },
  };
  myobj["UtileDulci"] = {
    label: "Utile Dulci",
    command: () => {
      router.push(
        {
          pathname: "/UtileDulci",
          // query: { username, policy },
        },
        "/UtileDulci"
      );
      setVisible(false);
      MixpanelRecord("Nav 2 UtileDulci", {
        message: "sideNavbar to UtileDulci page",
      });
    },
  };
  myobj["DemoVideos"] = {
    label: "Demo Videos",
    command: () => {
      router.push(
        {
          pathname: "/DemoVideos",
          // query: { username, policy },
        },
        "/DemoVideos"
      );
      setVisible(false);
      MixpanelRecord("Nav 2 Demo Videos", {
        message: "sideNavbar to Demo Videos page",
      });
    },
  };
  myobj["ConnecXions"] = {
    label: "ConnecXions",
    command: () => {
      router.push(
        {
          pathname: "/ConnecXions",
          // query: { username, policy },
        },
        "/ConnecXions"
      );
      setVisible(false);
      MixpanelRecord("Nav 2 ConnecXions", {
        message: "sideNavbar to ConnecXions page",
      });
    },
  };

  // myobj["ConnecXions"] = {
  //   label: "ConnecXions",
  //   items: [
  //     {
  //       label: "MS-Access",
  //       icon: "pi pi-file",
  //       command: () => {
  //         router.push(
  //           {
  //             pathname: "/AppStore/EUC_UI",
  //           },
  //           "/AppStore/EUC_UI"
  //         );
  //         setVisible(false);
  //         MixpanelRecord("Nav 2 ConnecXions", {
  //           message: "sideNavbar to ConnecXions page",
  //         });
  //       },
  //     },
  //     {
  //       label: "MS-Excel",
  //       icon: "pi pi-file",
  //       command: () => {
  //         router.push(
  //           {
  //             pathname: "/AppStore/MS_Excel",
  //             // query: { username },
  //             // query: { username, policy },
  //           },
  //           "/AppStore/MS_Excel"
  //         );
  //         setVisible(false);
  //         MixpanelRecord("Nav 2 ConnecXions", {
  //           message: "sideNavbar to ConnecXions page",
  //         });
  //       },
  //     },
  //   ],
  // };

  myobj["Td_bfir"] = {
    label: "BFIR",
    command: () => {
      router.push(
        {
          pathname: "/td_bfir",
          // query: { username, policy },
        },
        "/td_bfir"
      );
      setVisible(false);
      MixpanelRecord("Nav 2 Td_bfir", {
        message: "sideNavbar to Td_bfir page",
      });
    },
  };
  myobj["Regtech"] = {
    label: "RegReports",
    command: () => {
      router.push(
        {
          pathname: "/RegReports",
          // query: { username, policy },
        },
        "/RegReports"
      );
      setVisible(false);
      MixpanelRecord("Nav 2 Regtech", {
        message: "sideNavbar to Regtech page",
      });
    },
  };

  myobj["KnowledgeGraph"] = {
    label: "Knowledge Graph",
    command: () => {
      router.push(
        {
          pathname: "/KnowledgeGraph",
          // query: { username, policy },
        },
        "/KnowledgeGraph"
      );
      setVisible(false);
      MixpanelRecord("Nav 2 KnowledgeGraph", {
        message: "sideNavbar to KnowledgeGraph page",
      });
    },
  };
  myobj["Media"] = {
    label: "Media",
    command: () => {
      router.push(
        {
          pathname: "/Media",
          // query: { username, policy },
        },
        "/Media"
      );
      setVisible(false);
      MixpanelRecord("Nav 2 Media", {
        message: "sideNavbar to Media page",
      });
    },
  };
  // myobj["Dashboard"] = {
  //   label: "Dashboard",
  //   command: () => {
  //     // router.push("/RevenueSnapshot");
  //     router.push(
  //       {
  //         pathname: "/RevenueSnapshot",
  //         // query: { username },
  //         query: { username, policy },
  //       },
  //       "/RevenueSnapshot"
  //     );
  //     setVisible(false);
  //     MixpanelRecord("Nav 2 RevenueSnapshot", {
  //       user: getCookieValue("user"),
  //       message: "sideNavbar to RevenueSnapshot page",
  //     });
  //   },
  // };
  myobj["AppStore"] = {
    label: "App Store",
    command: () => {
      // window.location='/AppStore.html'
      // router.push("/Appstore");
      router.push(
        {
          pathname: "/AppStore",
          // query: { username },
          query: { username, policy },
        },
        "/AppStore"
      );
      setVisible(false);
      MixpanelRecord("Nav 2 Appstore", {
        message: "sideNavbar to Appstore page",
      });
    },
  };

  myobj["Presales"] = {
    label: "Presales",
    command: () => {
      // window.location='/Presales.html'
      router.push(
        {
          pathname: "/Presales",
          query: { username, policy },
        },
        "/Presales"
      );
      setVisible(false);
      MixpanelRecord("Nav 2 Presales", {
        message: "sideNavbar to Presales page",
      });
    },
  };

  myobj["StationH"] = {
    label: "StationH",
    command: () => {
      // window.location='/StationH.html'
      // router.push("/Appstore");
      router.push(
        {
          pathname: "/StationH",
          // query: { username },
          query: { username, policy },
        },
        "/StationH"
      );
      setVisible(false);
      MixpanelRecord("Nav 2 Appstore", {
        message: "sideNavbar to Appstore page",
      });
    },
  };

  myobj["Hackathon2024"] = {
    label: "Hackathon 2024",
    command: () => {
      // window.location='/AppStore.html'
      // router.push("/Appstore");
      router.push(
        {
          pathname: "/Hackathon2024",
          // query: { username },
          query: { username, policy },
        },
        "/Hackathon2024"
      );
      setVisible(false);
      MixpanelRecord("Nav 2 Hackathon", {
        message: "sideNavbar to Hackathon page",
      });
    },
  };

  const features = [];

  for (let k of feature) {
    if (myobj.hasOwnProperty(k)) {
      var item = myobj[k];
      features.push(item);
    }
  }

  const handleLogoutConfirmed = () => {
    console.log("Logout confirmed");
  };

  const styleObj = {
    width: "270px",
    border: "transparent",
    fontFamily: "Didact Gothic",
    color: "black",
  };
  const end2 = (
    <Image
      alt="logo"
      src="http://digitalstudio.eastus.cloudapp.azure.com:9971/images/harvis.png"
      height={100}
      width={80}
      className="mr-2"
      onClick={gotoHome}
    ></Image>
  );
  const end1 = (
    <div className="user-info" style={{ marginRight: 38 }}>
      {/* <img src="person.png" alt="User" className="user-avatar" /> */}
      <Image
        alt="logo"
        src="http://digitalstudio.eastus.cloudapp.azure.com/images/av.png"
        height="100"
        width="60"
        onClick={() => setProfile(true)}
        className="mr-2"
        style={{
          marginRight: 60,
          borderRadius: 100,
          height: 50,
          marginTop: 0,
        }}
      ></Image>
      <div
        className="user-name"
        style={{ fontFamily: "Didact Gothic", fontWeight: 600 }}
      >
        {showname}
      </div>
      {/* <a
          href="/"
          onClick={() =>
            MixpanelRecord("Logout", {
              message: "sideNavbar to Login Page",
            })
          }
          style={{ marginRight: 52, marginTop: 25 }}
        > */}

      <LogoutConfirmation onConfirm={handleLogoutConfirmed} />
      {/* <Image
            alt="logo"
            src="/logout.png"
            width={30}
            height={10}
            style={{ height: 30,position:"fixed",top:17,right:11}}
          ></Image> */}
    </div>

    //     <div className="grid">

    // <div className="col-2"  >
    //         <Image
    //           alt="logo"
    //           src="/images/av.png"
    //           height="100"
    //           width="60"
    //           onClick={() => setProfile(true)}
    //           className="mr-2"
    //           style={{
    //             marginRight: 60,
    //             borderRadius: 100,
    //             height: 50,
    //             marginTop: 10,
    //           }}
    //         ></Image>
    //       </div>
    //       <div
    //         className="col text-lg font-semibold"
    //         style={{ marginTop: 20, width:"auto",display:"flex",alignItems:"end" }}
    //       >
    //         {/* leo */}
    //         Boddoju venkata naga sai Priyanka
    //         {/* {showname} */}
    //       </div>

    //       <div className="col" style={{ marginTop: 22 }}>

    //         <a
    //           href="/"
    //           onClick={() =>
    //             MixpanelRecord("Logout", {
    //               message: "sideNavbar to Login Page",
    //             })
    //           }
    //           style={{ marginRight: 10, marginTop: 25 }}
    //         >
    //           <Image
    //             alt="logo"
    //             src="/logout.png"
    //             width={30}
    //             height={10}
    //             style={{ height: 30, marginLeft: "25%" }}
    //           ></Image>
    //         </a>
    //         {/* <p style={{color:"white"}}>{localStorage.getItem("inputValue")}</p> */}
    //       </div>
    //     </div>
  );

  const header = (
    <div className="grid">
      <div className="col-2">
        <Image
          alt="logo"
          src="http://digitalstudio.eastus.cloudapp.azure.com/images/ravi.png"
          width={50}
          height={50}
          style={{ borderRadius: 100, height: 50, marginLeft: "-192%" }}
        ></Image>
      </div>
      <div className="col-10">
        {/* <p style={{marginLeft:-38,marginTop:11}}>{localStorage.getItem("inputValue")}</p> */}
        <p
          style={{
            fontSize: "medium",
            fontWeight: 100,
            marginTop: -20,
            marginLeft: -34,
          }}
        >
          1000055366@hexaware.com
        </p>
      </div>
    </div>
  );
  const start = (
    <div
      className="card flex justify-content-center"
      style={{ zIndex: -10, fontFamily: "Didact Gothic" }}
    >
      <Sidebar visible={visible} onHide={() => setVisible(false)}>
        <div>
          <PanelMenu
            // style={{fontFamily:'Didact Gothic'}}
            model={features}
            target="_blank"
            viewportHeight={600}
            style={styleObj}
            menuWidth={270}
            id="Slidemenu"
            className="Slidemenu"
          ></PanelMenu>
        </div>
      </Sidebar>
      <Button
        icon="pi pi-bars"
        onClick={() => setVisible(true)}
        style={{ marginTop: 20, height: "100%" }}
      ></Button>
      {/* <Button label="Show" icon="pi pi-external-link" onClick={() => setProfile(true)} /> */}
      <Image
        alt="logo"
        label="Show"
        src="http://digitalstudio.eastus.cloudapp.azure.com/images/harvis.png"
        onClick={gotoHome}
        style={{ cursor: "pointer", margin: 20 }}
        height={50}
        width={150}
      ></Image>
    </div>
  );

  return (
    <>
      {/* <div className="Menu" style={{ marginTop: -8 }}>
        <Menubar
          className="menubar_sidenav"
          target="_blank"
          // end={end}
          centre={end2}
          end={end1}
          start={start}
          style={{
            position: "fixed",
            width: "100%",
            height: "10%",
            zIndex: 10,
            fontFamily: "Didact Gothic",
            marginLeft: -8,
          }}
        />
      </div> */}
      <div className="" style={{ marginTop: -8 }}>
        <Menubar
          className="flex w-full"
          start={start}
          // centre={end2}
          end={end1}
          style={{
            position: "fixed",
            height: 50,
            zIndex: 10,
            fontFamily: "Didact Gothic",
            marginLeft: -8,
          }}
        />
      </div>
    </>
  );
}
